import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

export default ({ data }) => {
  console.log(data)
  return (
    <Layout>
      <div class="bg-dark-red">
        <h1>Contentful Test</h1>
        <table>
          <thead>
            <tr>
              <th>relativePath</th>
              <th>prettySize</th>
              <th>extension</th>
              <th>birthTime</th>
            </tr>
          </thead>
          <tbody>
            {data.allContentfulPost.edges.map(({ node }, index) => (
              <tr key={index}>
                <td>{node.title}</td>
                <td>{node.childContentfulPostBodyTextNode.body}</td>
                <td></td>
                <td></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query MyQuery {
    allContentfulPost {
      edges {
        node {
          title
          childContentfulPostBodyTextNode {
            body
          }
        }
      }
    }
  }
`
